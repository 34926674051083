export const FareCalculator = (
  distance,
  time,
  rateDetails,
  instructionData,
  decimal,
  auth
) => {
  // console.log("auth", auth);
  let distanceRate = 0;
  if (auth && auth.profile && auth.profile.corporateadmin) {
    distanceRate = rateDetails.corporate_rate_per_unit_distance;
    // console.log(
    //   `===================> distanceRate ${distanceRate} corporateadmin ${auth.profile.corporateadmin}`
    // );
  } else {
    distanceRate = rateDetails.rate_per_unit_distance;
    // console.log("===================> distanceRate", distanceRate);
  }

  let baseCalculated =
    parseFloat(distanceRate) * parseFloat(distance) +
    parseFloat(rateDetails.rate_per_hour) * (parseFloat(time) / 3600);
  if (rateDetails.base_fare > 0) {
    baseCalculated = baseCalculated + rateDetails.base_fare;
  }
  if (instructionData && instructionData.parcelTypeSelected) {
    baseCalculated = baseCalculated + instructionData.parcelTypeSelected.amount;
  }
  if (instructionData && instructionData.optionSelected) {
    baseCalculated = baseCalculated + instructionData.optionSelected.amount;
  }
  let total =
    baseCalculated > parseFloat(rateDetails.min_fare)
      ? baseCalculated
      : parseFloat(rateDetails.min_fare);
  let convenienceFee = 0;
  if (
    rateDetails.convenience_fee_type &&
    rateDetails.convenience_fee_type == "flat"
  ) {
    convenienceFee = rateDetails.convenience_fees;
  } else {
    convenienceFee = (total * parseFloat(rateDetails.convenience_fees)) / 100;
  }
  let grand = total + convenienceFee;

  // Function to round up to the next multiple of 5
  function roundUpToNearestMultipleOf5(value) {
    return Math.ceil(value / 5) * 5;
  }

  // ...

  // Round totalCost and grandTotal to the nearest multiple of 5
  const roundedTotalCost = roundUpToNearestMultipleOf5(total);
  const roundedGrandTotal = roundUpToNearestMultipleOf5(grand);

  // ...

  return {
    totalCost: parseFloat(roundedTotalCost.toFixed(decimal)),
    grandTotal: parseFloat(roundedGrandTotal.toFixed(decimal)),
    convenience_fees: parseFloat(convenienceFee.toFixed(decimal)),
  };
};
