import React from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "../styles/staticPages.js";
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import headerback from "../assets/img/header-back.jpg";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function AboutUs(props) {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div style={{ margin: "-8px" }}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax small filter image={headerback} />

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <br />
          <h2
            style={{
              textAlign: isRTL === "rtl" ? "right" : "left",
              position: "relative",
              marginTop: "30px",
              minHeight: "32px",
              color: "#383838",
              textDecoration: "none",
            }}
          >
            {t("about_us")}
          </h2>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {t("about_us_content1")}
          </div>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {t("about_us_content2")}
          </div>
          <br />
        </div>
      </div>

      <Footer />
    </div>
  );
}
