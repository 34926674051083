import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import { colors } from "../components/Theme/WebTheme";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "../styles/staticPages.js";
import Parallax from "components/Parallax/Parallax";
import "./FAQ.css";
import headerback from "../assets/img/header-back.jpg";

const useStyles = makeStyles(styles);

const dashboardRoutes = [];

const faqData = [
  {
    question: "How do I book a ride or a delivery on Beba Fleet",
    answer:
      "Using the app is quite straightforward,once you are signed in,the app is quite easy to navigate.When stuck,don't hesitate to contact support",
  },
  {
    question:
      "Is my payment secure when using M-Pesa on Flutter wave on the app",
    answer: "Absolutely",
  },
  {
    question: "Can I track my driver or delivery in real-time",
    answer: "Of Course",
  },
  {
    question:
      "What happens if I need to cancel my booking? Is there a cancellation fee?",
    answer:
      "Clicking cancel button on bboking pages does the Job.No cancellation fee whatsoever",
  },
  {
    question: "Is it possible to schedule rides or deliveries in advance?",
    answer:
      "Yes. You can do so by clicking on the Request later button on the App",
  },
  {
    question:
      "How can I rate my driver or delivery person and provide feedback on my experience?",
    answer:
      "On completing your ride,you will get a dialogue propmt to do the rating",
  },
  {
    question:
      "Are there any loyalty rewards or referral programs for frequent users?",
    answer:
      "Sure,on the settings screen,there is a referal screen from which you can grap the referral code which gives rewards on succesfull sign ups",
  },
  {
    question:
      "What measures are in place for my safety and security when using Beba Fleet?",
    answer:
      "User data is encrypted and passwords hashed.The servers are secured by powerful firewalls",
  },
  {
    question: "Is there a Support /emergency number on the app",
    answer: "Absolutely.Press on the Emergency call on the settings screen",
  },
  // Add more FAQ items as needed
];
const faqData1 = [
  {
    question: "How do I sign up as a driver on Beba Fleet?",
    answer:
      "On the Sign Up screen,tap on the driver's tab and fill in your details",
  },
  {
    question: "What are the requirements and qualifications for drivers?",
    answer:
      "Driving licence,NTSA Inspection,Acceptable cars:1200cc and below,year 2011 and above,diligence",
  },
  {
    question:
      "How do I add my bank account for withdrawals, and how often can I make withdrawals?",
    answer: "Tap the documents tab on the setting screens and add your Payment",
  },
  {
    question:
      "What documents do I need to provide to verify my identity and vehicle information?",
    answer:
      "Driving license,Psv badge valid,Inspection report,Ntsa inspection report,Psv insurance,Picture of car",
  },
  {
    question:
      "What is the process for receiving payments through M-Pesa on Flutter wave?",
    answer:
      "Weekly bank/mpesa deposits are made to drivers against their earnings and payment details",
  },
  {
    question: "Can I choose my working hours and areas of operation?",
    answer: "Absolutely,you got control over what hours you are on duty",
  },
  {
    question:
      "What support and resources are available to drivers if I encounter issues while using the app?",
    answer:
      "There is a support tap on the settings screen to contact Company staff for technical support",
  },
  {
    question: "Is there a Support /emergency number on the app",
    answer: "Absolutely.Press on the Emergency call on the settings screen",
  },
  // Add more FAQ items as needed
];

export default function FAQPage() {
  const classes = useStyles();

  return (
    <div style={{ margin: "-8px" }}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
      />
      <Parallax small filter image={headerback} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className="faq-container">
          <h1>Frequently Asked Questions</h1>
          <br></br>
          <br></br>

          <h2>For Clients</h2>
          <br></br>

          <ul className="faq-list">
            {faqData.map((item, index) => (
              <li key={index}>
                <details>
                  <summary>{item.question}</summary>
                  <p>{item.answer}</p>
                </details>
              </li>
            ))}
          </ul>
          <br></br>
          <br></br>

          <h2>For Drivers</h2>
          <br></br>

          <ul className="faq-list">
            {faqData1.map((item, index) => (
              <li key={index}>
                <details>
                  <summary>{item.question}</summary>
                  <p>{item.answer}</p>
                </details>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
}
